//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import { firebase } from "firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import DialogMessage from "../elements/DialogMessage";
import Loading from "vue-loading-overlay";
import FMS from "../../services/FMS";
import EventBus from "../../../event-bus";

export default {
  components:{
    DialogMessage,
    Loading
  },
  data: () => ({
    email: "",
    password: "",
    showDialog: false,
    loading: false,
    title: "",
  }),
  methods: {
    login() {
      this.loading = true

      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
          .then(async (userCredential) => {
            //this.loading = false
            const user = userCredential.user;
            this.$session.start();
            this.$session.set("jwt", user.accessToken);

            var result = await FMS.postUserInfo(this.email, user.accessToken)
            this.$session.set("user", result.user);

            if(result.user.user_type === "b2b" || result.user.user_type === "provider" || result.user.user_type === "b2c"){
              EventBus.$emit("login");
              this.$router.replace({name: "order_create"});
            }
            else{
              alert('Your user type is not allowed to see this page');
            }

          })
          .catch((error) => {
            this.loading = false
            this.title = error.message
            this.showDialog = true;
          });

    },
    confirm() {
      this.showDialog = false;
    },
  }
};
